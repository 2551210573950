@function pow($number, $exponent) {
  $value: 1rem;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

@mixin defaultTypeStyle {
  &.font-normal {
    font-family: var(--whoop-font-family-normal);
  }
  &.font-bold {
    font-family: var(--whoop-font-family-bold);
  }
  &.font-semibold {
    font-family: var(--whoop-font-family-semibold);
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.transform-none {
    text-transform: none;
  }
}

@mixin defaultHeadingStyle {
  @include defaultTypeStyle;
  font-weight: 600;
  text-transform: none;
  margin: 0;
  font-family: var(--whoop-font-family-normal);
  line-height: 1.2em;
  letter-spacing: -0.005em;
}

@mixin defaultBodyStyle {
  @include defaultTypeStyle;
  text-transform: none;
  margin: 0;
  font-family: var(--whoop-font-family-normal);
  font-weight: normal;
  line-height: 1.2em;
  letter-spacing: -0.005em;
}

@mixin defaultNumberStyle {
  font-family: var(--dinpro-font-family-normal);
  font-weight: 700;
}

// Based on a 1.125 rem ratio
// the pow function shows the relationship to the ratio
$font-size-ratio: 1.125;

$p5: 1rem;
$p4: calc(#{$font-size-ratio} * 1rem);
$p3: pow($font-size-ratio, 2);
$p2: pow($font-size-ratio, 3);
$p1: pow($font-size-ratio, 4);

$h6: $p1;
$h5: pow($font-size-ratio, 5);
$h4: pow($font-size-ratio, 6);
$h3: pow($font-size-ratio, 7);
$h2: pow($font-size-ratio, 8);
$h1: pow($font-size-ratio, 9);

$t2: pow($font-size-ratio, 10);
$t1: pow($font-size-ratio, 11);

$c1: calc(1rem / #{$font-size-ratio});
$c2: calc(#{$c1} / #{$font-size-ratio});

$n6: calc(1rem / #{$font-size-ratio});
$n5: 1rem;
$n4: pow($font-size-ratio, 4);
$n3: pow($font-size-ratio, 8);
$n2: pow($font-size-ratio, 11);
$n1: pow($font-size-ratio, 13);

@mixin p1 {
  @include defaultBodyStyle;
  font-size: $p1;
}
@mixin p2 {
  @include defaultBodyStyle;
  font-size: $p2;
}
@mixin p3 {
  @include defaultBodyStyle;
  font-size: $p3;
}
@mixin p4 {
  @include defaultBodyStyle;
  font-size: $p4;
}
@mixin p5 {
  @include defaultBodyStyle;
  font-size: $p5;
}

@mixin t1 {
  @include defaultHeadingStyle;
  font-size: $t1;
}
@mixin t2 {
  @include defaultHeadingStyle;
  font-size: $t2;
}

@mixin c1 {
  @include defaultBodyStyle;
  font-size: $c1;
}
@mixin c2 {
  @include defaultBodyStyle;
  font-size: $c2;
}

@mixin h1 {
  @include defaultHeadingStyle;
  font-size: $h1;
}
@mixin h2 {
  @include defaultHeadingStyle;
  font-size: $h2;
}
@mixin h3 {
  @include defaultHeadingStyle;
  font-size: $h3;
}
@mixin h4 {
  @include defaultHeadingStyle;
  font-size: $h4;
}
@mixin h5 {
  @include defaultHeadingStyle;
  font-size: $h5;
}
@mixin h6 {
  @include defaultHeadingStyle;
  font-size: $h6;
}

@mixin n1 {
  @include defaultNumberStyle;
  font-size: $n1;
}
@mixin n2 {
  @include defaultNumberStyle;
  font-size: $n2;
}
@mixin n3 {
  @include defaultNumberStyle;
  font-size: $n3;
}
@mixin n4 {
  @include defaultNumberStyle;
  font-size: $n4;
}
@mixin n5 {
  @include defaultNumberStyle;
  font-size: $n5;
}
@mixin n6 {
  @include defaultNumberStyle;
  font-size: $n6;
}

@mixin b1 {
  @include defaultTypeStyle;
  margin: 0;
  font-weight: normal;
  font-family: var(--whoop-font-family-normal);
  letter-spacing: -0.005em;
  font-size: $c1;
  font-weight: 700;
  text-transform: uppercase;
}
